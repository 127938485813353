<template>
  <section class="mt-4 pb-5">
    <div class="container">
      <div class="inner_page_two_sides">

        <div class="right_side">

          <div class="section_payment_breadcrumbs">
            <span><a href="/">{{ $t('common.main_page') }}</a></span>
            <span>{{ $t('common.aml') }}</span>
          </div>

          <div class="section_header mt-3 mb-3">
            <span>{{ $t('common.aml') }}</span>
          </div>

          <div class="section_content section_content_partners_monitoring" v-html="$t('aml.text')"/>

        </div>

      </div>

    </div></section>
</template>

<script>
export default {

}
</script>
